import React from 'react';
import { Award, Calendar, Medal } from 'lucide-react';

const AchievementCard = ({ title, period, description, type = 'achievement' }) => (
  <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all duration-300 hover:-translate-y-1 border border-gray-200">
    <div className="flex items-start gap-4">
      {type === 'achievement' ? (
        <Award className="w-6 h-6 text-gray-700 flex-shrink-0 mt-1" />
      ) : (
        <Medal className="w-6 h-6 text-gray-700 flex-shrink-0 mt-1" />
      )}
      <div>
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-xl font-bold text-gray-900">{title}</h3>
          <span className="text-sm text-gray-500 flex items-center gap-1">
            <Calendar className="w-4 h-4" />
            {period}
          </span>
        </div>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

const Achievements = () => {
  const achievements = [
    {
      title: "Student Outreach Program - Tutor",
      period: "10/2020 - 04/2022",
      description: "Led a student outreach initiative in Nilgiri district during my bachelor's degree, developing and implementing computer literacy programs for lower primary students, covering hardware components and basic computing concepts.",
      type: 'achievement'
    },
    {
      title: "Polarion ALM Certification",
      period: "12/2023 - 01/2024",
      description: "Achieved certification in Polarion ALM (Application Lifecycle Management) with focus on VelocityScript development",
      type: 'certificate'
    },
    {
      title: "CISCO Network Academy",
      period: "10/2021",
      description: "Introduction to cybersecurity certification, covering fundamental concepts and best practices in network security.",
      type: 'certificate'
    },
    {
      title: "INFOSYS BPM",
      period: "10/2021",
      description: "Infosys BPM skill development program - Online soft skills training.",
      type: 'certificate'
    }
  ];

  return (
    <section id="achievements" className="section-padding bg-gray-50">
      <div className="container-width">
        <h2 className="section-title text-gray-900">Achievements & Certifications</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {achievements.map((achievement, index) => (
            <AchievementCard key={index} {...achievement} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Achievements;