import React from 'react';
import { Code, Shield, Globe, Award } from 'lucide-react';

const SkillCategory = ({ title, skills, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all duration-300">
    <div className="flex items-center gap-3 mb-4">
      <Icon className="w-6 h-6 text-gray-700" />
      <h3 className="text-xl font-bold text-gray-900">{title}</h3>
    </div>
    <div className="flex flex-wrap gap-2">
      {skills.map((skill, i) => (
        <span 
          key={i} 
          className="px-3 py-1 bg-gray-50 text-gray-700 rounded-full text-sm hover:bg-gray-200 transition-colors duration-200"
        >
          {skill}
        </span>
      ))}
    </div>
  </div>
);

const Skills = () => {
  const skillCategories = [
    {
      title: "Technical Skills",
      icon: Code,
      skills: [
        "Python",
        "React",
        "Node.js",
        "MongoDB",
        "Docker",
        "Java",
        "C++",
        "JavaScript",
        "React Native",
      ]
    },
    {
      title: "Security & Tools",
      icon: Shield,
      skills: [
        "Cryptography",
        "X.509 Certificates",
        "OpenSSL",
        "Cyber Security",
        "Azure DevOps",
        "Git",
        "Jira",
        "Polarion",
        "Enterprise Architect",
        "Axivion",
        "Digital Ocean",
        "OpenAI",
        "Google Cloud Platform",
      ]
    },
    {
      title: "Languages",
      icon: Globe,
      skills: [
        "English (Full Professional)",
        "German (Elementary)",
        "Hindi (Professional)",
        "Malayalam (Native)",
        "Tamil (Professional)",
        "Kannada (Limited)"
      ]
    },
    {
      title: "Specialized Skills",
      icon: Award,
      skills: [
        "Software Architecture",
        "Full Stack Development",
        "Requirements Engineering",
        "Embedded Systems",
        "Agile Development",
      ]
    }
  ];

  return (
    <section id="skills" className="section-padding bg-white">
      <div className="container-width">
        <h2 className="section-title text-gray-900">Skills</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {skillCategories.map((category, index) => (
            <SkillCategory key={index} {...category} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;