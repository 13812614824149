import React from 'react';

const EducationCard = ({ degree, institution, period, grade, courses }) => (
  <div className="relative pl-4 border-l-4 border-gray-500 bg-white rounded-lg shadow-md p-6 mb-8 hover:shadow-lg transition-all duration-300 ease-in-out hover:-translate-y-1">
    <h3 className="text-xl font-bold text-gray-900 mb-2">{degree}</h3>
    <div className="text-lg font-semibold text-gray-700 mb-2">{institution}</div>
    <div className="flex justify-between text-gray-500 mb-4">
      <span>{period}</span>
      <span>Grade: {grade}</span>
    </div>
    <div className="flex flex-wrap gap-2">
      {courses.map((course, index) => (
        <span 
          key={index} 
          className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm hover:bg-gray-200 hover:text-gray-900 transition-colors duration-200"
        >
          {course}
        </span>
      ))}
    </div>
  </div>
);
const Education = () => {
  const education = [
    {
      degree: "Masters Of Science - Applied Computer Science",
      institution: "SRH HOCHSCHULE HEIDELBERG",
      period: "04/2023 - 03/2025",
      grade: "1.8",
      courses: [
        "Software Architecture & Development",
        "Advanced databases",
        "International Project Management",
        "UI/UX designing",
        "Implementation and testing",
        "IT Security",
        "Artificial Intelligence",
        "Google Cloud Platform",
        "Software Architecture & Development",
      ]
    },
    {
      degree: "Bachelor of Computer Applications",
      institution: "BHARATHIAR UNIVERSITY COIMBATORE",
      period: "07/2019 - 06/2022",
      grade: "2.3",
      courses: [
        "System Software",
        "Computer Architecture",
        "C/C++",
        "Java",
        "Python",
        "Javascript",
        "Databases",
        "Computer networks",
        "Computer Hardware",
        "IoT"
      ]
    },
    {
      degree: "Computer Science and Information Technology [VHSE]",
      institution: "GVHS (THS) SULTHAN BATHERY",
      period: "06/2016 - 03/2018",
      grade: "2.2",
      courses: [
        "Entrepreneurship Development",
        "Computer Hardware Maintenance",
        "Networking",
        "Web Application Development",
        "C/C++",
        "Visual Communication"
      ]
    }
  ];

  return (
    <section id="education" className="section-padding bg-white">
      <div className="container-width">
        <h2 className="section-title">Education</h2>
        <div className="space-y-8">
          {education.map((edu, index) => (
            <EducationCard key={index} {...edu} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Education;