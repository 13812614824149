import React from 'react';
import { Calendar, Github, ExternalLink, FileText, Figma } from 'lucide-react';

const ProjectCard = ({ 
  title, 
  period, 
  description, 
  details = [], 
  technologies = [], 
  githubLink, 
  demoLink,
  documentationLink,
  figmaLink 
}) => (
  <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all duration-300 hover:-translate-y-1 border border-gray-200">
    <div className="flex justify-between items-start gap-4 mb-4">
      <h3 className="text-xl font-bold text-gray-900">{title}</h3>
      {period && (
        <span className="text-sm text-gray-500 whitespace-nowrap flex items-center gap-1.5 shrink-0">
          <Calendar size={14} />
          {period}
        </span>
      )}
    </div>
    <p className="text-gray-600 mb-4">{description}</p>
    {details.length > 0 && (
      <ul className="list-disc list-inside space-y-2 text-gray-600 mb-4">
        {details.map((detail, i) => (
          <li key={i} className="text-sm">{detail}</li>
        ))}
      </ul>
    )}
    
    {/* Links section */}
    {(githubLink || demoLink || documentationLink || figmaLink) && (
      <div className="flex flex-wrap gap-4 mb-4">
        {githubLink && (
          <a 
            href={githubLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-1.5 text-sm text-gray-600 hover:text-gray-900 transition-colors duration-200"
          >
            <Github size={16} />
            <span>GitHub</span>
            <ExternalLink size={12} className="opacity-50" />
          </a>
        )}
        {demoLink && (
          <a 
            href={demoLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-1.5 text-sm text-gray-600 hover:text-gray-900 transition-colors duration-200"
          >
            <ExternalLink size={16} />
            <span>Live Demo</span>
          </a>
        )}
        {documentationLink && (
          <a 
            href={documentationLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-1.5 text-sm text-gray-600 hover:text-gray-900 transition-colors duration-200"
          >
            <FileText size={16} />
            <span>Documentation</span>
            <ExternalLink size={12} className="opacity-50" />
          </a>
        )}
        {figmaLink && (
          <a 
            href={figmaLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-1.5 text-sm text-gray-600 hover:text-gray-900 transition-colors duration-200"
          >
            <Figma size={16} />
            <span>Design</span>
            <ExternalLink size={12} className="opacity-50" />
          </a>
        )}
      </div>
    )}

    <div className="flex flex-wrap gap-2">
      {technologies.map((tech, i) => (
        <span 
          key={i} 
          className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm font-medium hover:bg-gray-200 transition-colors duration-200"
        >
          {tech}
        </span>
      ))}
    </div>
  </div>
);

const Projects = () => {
  const projects = [
    {
      title: "Personal Portfolio Website",
      description: "Developed responsive portfolio website using React with automated GitHub Actions deployment",
      details: [
        "Built reusable component library and CI/CD pipeline for streamlined project updates",
        "Implemented responsive design with modern UI components"
      ],
      technologies: ["React", "GitHub Actions", "Tailwind CSS"],
      githubLink: "https://github.com/alan-kmathew/portfolio",
      demoLink: "https://alankmathew.com"
    },
    {
      title: "Advance Database Car Rental",
      period: "05/2024 - 06/2024",
      description: "Built enterprise car rental system with distributed database architecture",
      details: [
        "Implemented distributed database architecture using MongoDB, Neo4j, and Redis",
        "Built intelligent fleet management with real-time pricing",
        "Implemented route optimization using graph algorithms"
      ],
      technologies: ["React", "Node.js", "MongoDB", "Neo4j", "Redis"],
      githubLink: "https://github.com/alan-kmathew/Advance-Database-Car-Rental",
      documentationLink: "https://1drv.ms/b/c/927f4c1cf4825c6c/IQRK3LezAp5BQqve0E4fZwHiAQzuWgoIgP4OKdLvuY4hgpY"
    },
    {
      title: "Comparative Analysis Gemini 1.5 vs Claude 3 Sonnet",
      period: "04/2024 - 05/2024",
      description: "Developed comparison platform analyzing AI model performance",
      details: [
        "Implemented systematic prompt engineering tests",
        "Created comprehensive analysis dashboard"
      ],
      technologies: ["React", "LLM Implementation", "Prompt Engineering"],
      githubLink: "https://github.com/alan-kmathew/ACS-2024-Comparative-Analysis-Gemini-1.5-Vs-Claude-3-Sonnet",
      documentationLink: "https://1drv.ms/b/c/927f4c1cf4825c6c/IQQ5cgESpofZRZ6IRQL-VpA4AePbM_rVhLPTzgjUgZBuiyA"

    },
    {
      title: "Usability Testing Project Planning Tool",
      period: "02/2024 - 03/2024",
      description: "Developed Jira-inspired project management tool",
      details: [
        "Built with React Native and CI/CD automation",
        "Implemented comprehensive Jest testing with 85%+ coverage",
        "Set up automated deployments using Jenkins, Github and DigitalOcean"
      ],
      technologies: ["React Native", "Jest", "Jenkins", "DigitalOcean"],
      githubLink: "https://github.com/alan-kmathew/Usability_Testing_Project_Planning_Tool"
    },
    {
      title: "Job Pilot",
      period: "01/2024 - 02/2024",
      description: "Intelligent job application platform",
      details: [
        "Integrated with OpenAI API for enhanced features",
        "Built automated system for email tracking and cover letter generation"
      ],
      technologies: ["React", "Node.js", "OpenAI API", "MongoDB"],
      githubLink: "https://github.com/alan-kmathew/sad-01-24-group-2-jobpilot"
    },
    {
      title: "Braille Mobile Application",
      period: "11/2023 - 12/2023",
      description: "Mobile braille training application with physical device integration",
      details: [
        "Connected to physical braille devices for hands-on learning",
        "Collaborated with university professors for expert guidance",
        "Developed interactive learning modules with haptic feedback",
        "Created comprehensive documentation and user guides"
      ],
      technologies: ["React Native", "Node.js", "MongoDB", "Hardware Integration"],
      githubLink: "https://github.com/alan-kmathew/BrailleApp",
      figmaLink: "https://www.figma.com/design/FuolYEpCslDxp7O5Xg1FYb/Untitled?node-id=0-1&t=QEgCp9HHxY54ZI57-1"
    },
    { 
      title: "Transportation App",
      period: "06/2023 - 07/2023",
      description: "Responsive transportation application",
      details: [
        "Designed from wireframes to deployment using Figma and React",
        "Implemented responsive layouts for efficient route management",
        "Created comprehensive UI/UX design system"
      ],
      technologies: ["React", "Figma", "Responsive Design"],
      figmaLink: "https://www.figma.com/design/yWNezVp9gFX6srvP1VUIiX/GoRoute",
      documentationLink: "https://1drv.ms/b/c/927f4c1cf4825c6c/IQScY3H6x5JhQ4O0FUo1aG1wAYRhiCmoJswwm9pOX2M2GYw"
    },
    {
      title: "Hotel Management System",
      period: "05/2023 - 06/2023",
      description: "Full-stack hotel management system",
      details: [
        "Automated booking and front desk operations",
        "Created RESTful APIs for efficient data handling"
      ],
      technologies: ["React", "Java", "MySQL", "REST APIs"],
      githubLink: "https://github.com/alan-kmathew/online-hotel-management-frontend",
      demoLink: "https://hotel-management-xi.vercel.app"
    },
    {
      title: "AI Supported Local Language Training",
      period: "04/2023 - 05/2023",
      description: "AI-powered dialect translation system",
      details: [
        "Focused on German regional languages",
        "Managed complete project lifecycle including research and stakeholder coordination",
        "Implemented machine learning models for dialect recognition",
        "Developed comprehensive research documentation"
      ],
      technologies: ["AI", "Natural Language Processing", "Project Management"],
      documentationLink: "https://1drv.ms/b/c/927f4c1cf4825c6c/IQS9XsD_0T03Qrt61k0b5g6YAbOA2OP5Yurw6TTwsBmfB7Y"
    },
    {
      title: "Internet Service Providers System",
      period: "12/2021 - 05/2022",
      description: "Web application for ISP and broadband provider",
      details: [
        "Built customer management system with billing and subscription tracking",
        "Implemented integrated ticketing system for support requests"
      ],
      technologies: ["Web Development", "Database Management", "Customer Support Systems"]
    },
    {
      title: "Home Automation",
      period: "02/2018 - 03/2018",
      description: "Smart home control system",
      details: [
        "Developed mobile application for household equipment control",
        "Integrated Blynk API for real-time device status updates",
        "Used Arduino and ESP8266 for hardware integration"
      ],
      technologies: ["Arduino", "ESP8266", "Blynk API", "IoT"]
    },
    {
      title: "Smart Shoes for Visually Impaired",
      period: "01/2017 - 02/2018",
      description: "Assistive technology project",
      details: [
        "Designed sensor-based feedback system using ultrasonic sensors",
        "Created proof of concept for smart shoes to assist visually impaired individuals"
      ],
      technologies: ["Arduino", "Ultrasonic Sensors", "Embedded Systems"]
    }
  ];

  return (
    <section id="projects" className="section-padding bg-gray-50">
      <div className="container-width">
        <h2 className="section-title text-gray-900">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;