import React from 'react';
import { Mail, Phone, MapPin, Github, Linkedin, ExternalLink } from 'lucide-react';

const ContactLink = ({ href, icon, children, external = false }) => (
  <a 
    href={href}
    className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-all duration-200 hover:scale-105 group"
    target={external ? "_blank" : undefined}
    rel={external ? "noopener noreferrer" : undefined}
  >
    {icon}
    <span>{children}</span>
    {external && (
      <ExternalLink size={16} className="opacity-0 group-hover:opacity-100 transition-opacity" />
    )}
  </a>
);

const Contact = () => (
  <section id="contact" className="section-padding bg-gradient-to-b from-gray-50 to-white">
    <div className="container-width">
      <h2 className="section-title text-center">Contact</h2>
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-xl p-8 hover:shadow-2xl transition-all duration-300">
        <div className="space-y-6">
          <div className="flex flex-col items-center gap-6">
            <ContactLink 
              href="mailto:alankmathew001@gmail.com" 
              icon={<Mail className="w-6 h-6 text-gray-700" />}
            >
              alankmathew001@gmail.com
            </ContactLink>
            
            <ContactLink 
              href="tel:017658106572" 
              icon={<Phone className="w-6 h-6 text-gray-700" />}
            >
              017658106572
            </ContactLink>
            
            <div className="flex items-center gap-2 text-gray-600">
              <MapPin className="w-6 h-6 text-gray-700" />
              <span>Kassel, Germany</span>
            </div>
          </div>

          <div className="border-t border-gray-200 my-8" />

          <div className="flex justify-center gap-8">
            <ContactLink 
              href="https://github.com/alan-kmathew" 
              icon={<Github className="w-8 h-8" />}
              external
            >
              GitHub
            </ContactLink>
            
            <ContactLink 
              href="https://linkedin.com/in/alan-k-015048136" 
              icon={<Linkedin className="w-8 h-8" />}
              external
            >
              LinkedIn
            </ContactLink>
          </div>

          <div className="text-center mt-8">
            <p className="text-gray-500 text-sm">
              Open to opportunities and collaborations
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Contact;